export function longToShortCurrency(cur: string) {
  if (cur === "EUR") {
    return "€"
  } else if (cur === "DOLLAR" || cur === "USD") {
    return "$"
  } else if (cur === "POUND" || cur === "GBP") {
    return "£"
  } else {
    return ""
  }
}
