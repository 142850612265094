import ProductPrice from "@/models/ProductPrice"
import axios from "axios"

export default class SalesService {
  /* PRODUCT CATEGORIES */
  createCategory(salesCategory: any) {
    return axios.post("productcategories", salesCategory).then((res) => res.data)
  }

  editCategory(categoryId: string, salesCategory: any) {
    return axios.put(`productcategories/${categoryId}`, salesCategory).then((res) => res.data)
  }

  deleteCategory(id: string) {
    return axios.delete(`productcategories/${id}`).then((res) => res.data)
  }

  /* PRODUCT CATEGORY DETAILS */

  getCategories() {
    return axios.get("productcategories/list").then((res) => res.data)
  }

  getCategoryDetails(id: string) {
    return axios.get(`productcategorydetails/${id}`).then((res) => res.data)
  }

  createCategoryDetails(id: string, data: any) {
    return axios
      .post(`productcategorydetails?productCategoryId=${id}`, data)
      .then((res) => res.data)
  }

  updateCategoryDetails(id: string, detailsId: string, data: any) {
    return axios
      .put(`productcategorydetails/${detailsId}?productCategoryId=${id}`, data)
      .then((res) => res.data)
  }

  /* PRODUCT */
  linkProductToCategory(catId: string, prodId: string) {
    return axios
      .post(`productcategories/${catId}/product?productId=${prodId}`)
      .then((res) => res.data)
  }

  unlinkProductFromCategory(catId: string, prodId: string) {
    return axios
      .delete(`productcategories/${catId}/product?productId=${prodId}`)
      .then((res) => res.data)
  }

  getProductsList(catId: string) {
    return axios.get(`products/list?productCategoryId=${catId}`).then((res) => res.data)
  }

  getProducts() {
    return axios.get(`products`).then((res) =>
      res.data.sort((a: any, b: any) => {
        return a.updatedTime > b.updatedTime ? -1 : 1
      })
    )
  }

  getProduct(id: string) {
    return axios.get(`products/${id}`).then((res) => res.data)
  }

  createProduct(data: any) {
    return axios.post("products", data).then((res) => res.data)
  }

  updateProduct(prodId: string, data: any) {
    return axios.put(`products/${prodId}`, data).then((res) => res.data)
  }

  deleteProduct(id: string) {
    return axios.delete(`products/${id}`).then((res) => res.data)
  }

  /* PRODUCT DETAILS */
  createProductDetails(productId: string, categoryId: string, data: any) {
    return axios
      .post(`productdetails?productId=${productId}&productCategoryId=${categoryId}`, data)
      .then((res) => res.data)
  }

  updateProductDetails(productId: string, prodDetailsId: string, data: any) {
    return axios
      .put(`productdetails/${prodDetailsId}?productId=${productId}`, data)
      .then((res) => res.data)
  }

  /* LANGUAGES */
  getLanguages() {
    return axios.get("languages").then((res) => res.data)
  }

  /* REGIONS */
  getRegions() {
    return axios.get("regions").then((res) => res.data)
  }

  /* ORGANIZATION TYPES */
  getOrganizationTypes() {
    return axios.get("organizationtypes").then((res) => res.data)
  }

  /* SALES STATUSES */
  getSalesStatuses() {
    return axios.get("salestatuses").then((res) => res.data)
  }

  /* CURRENCIES */
  getCurrencies() {
    return axios.get("currencies").then((res) => res.data)
  }

  /* PRODUCT IMAGES */
  async createProductImage(productId: string, data: File, priority?: any): Promise<string> {
    const form = new FormData()
    form.append("file", data)
    const res = await axios.post(
      `productimages?productId=${productId}&priority=${priority || 0}`,
      form
    )
    return res.data
  }

  getProductImage(productId: string, token?: string) {
    if (token && token.length) {
      return axios
        .create()
        .get(`productimages/${productId}/image`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let image = URL.createObjectURL(res.data)
          return image
        })
    } else {
      return axios.get(`productimages/${productId}/image`, { responseType: "blob" }).then((res) => {
        let image = URL.createObjectURL(res.data)
        return image
      })
    }
  }

  getProductImageDetails(productImageId: string) {
    return axios.get(`productimages/${productImageId}`).then((res) => res.data)
  }

  deleteImage(imageId: string) {
    return axios.delete(`productimages/${imageId}`).then((res) => res.data)
  }

  /* PRODUCT PRICES */
  createProductPrice(productId: string, data: any) {
    return axios.post(`productprices?productId=${productId}`, data).then((res) => res.data)
  }

  updateProductPrice(priceId: string, data: any) {
    return axios.put(`productprices/${priceId}`, data).then((res) => res.data)
  }

  /* SALES */
  postSale(orgId: string, data: any) {
    return axios.post(`sales?organizationId=${orgId}`, data).then((res) => res.data)
  }

  getSales() {
    return axios.get("sales/list").then((res) => res.data)
  }

  getSalesForOrg(organizationId: string) {
    return axios.get("sales?organizationId=" + organizationId).then((res) => res.data)
  }

  deleteSale(saleId: string) {
    return axios.delete(`sales/${saleId}`).then((res) => res.data)
  }

  updateSale(saleId: string, data: any) {
    return axios.put(`sales/${saleId}`, data).then((res) => res.data)
  }

  addAssignee(saleId: string, userId: string) {
    return axios.post("sales/" + saleId + "/assignee?userId=" + userId).then((res) => res.data)
  }

  deleteAssignee(saleId: string, userId: string) {
    return axios.delete("sales/" + saleId + "/assignee?userId=" + userId).then((res) => res.data)
  }

  /* QUOTATIONS */
  createQuotation(saleId: string, data: any) {
    return axios.post(`quotations?saleId=${saleId}`, data).then((res) => res.data)
  }

  getQuotations(quotationId: string) {
    return axios.get(`quotations/${quotationId}`).then((res) => {
      try {
        res.data.categories = res.data.categories.sort((a: any, b: any) =>
          a.priority < b.priority ? -1 : 1
        )
        res.data.categories.forEach((cat: any) => {
          cat.products = cat.products.sort((a: any, b: any) =>
            a.product.priority < b.product.priority ? -1 : 1
          )
        })
      } catch (err: any) {
        console.log("sorting failed")
      }
      return res.data
    })
  }

  createQuotationCategory(quotationId: string, categoryId: string, data: any) {
    return axios
      .post(
        `quotationproductcategories?productCategoryId=${categoryId}&quotationId=${quotationId}`,
        data
      )
      .then((res) => res.data)
  }

  createQuotationProduct(categoryId: string, productId: string, data: any) {
    return axios
      .post(
        `quotationproducts?quotationProductCategoryId=${categoryId}&productId=${productId}`,
        data
      )
      .then((res) => res.data)
  }

  updateQuotationProduct(categoryId: string, productId: string, data: any, token?: string) {
    return axios
      .put(`quotationproducts/${productId}?quotationProductCategoryId=${categoryId}`, data)
      .then((res) => res.data)
  }

  selectPublicProduct(product: any, selected: boolean, token: string, payload: any) {
    return axios
      .create()
      .put(`quotationproducts/${product.id}/select?selected=${selected}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
  }

  deleteQuotationProduct(productId: string) {
    return axios.delete(`quotationproducts/${productId}`).then((res) => res.data)
  }

  deleteQuotationCategory(categoryId: string) {
    return axios.delete(`quotationproductcategories/${categoryId}`).then((res) => res.data)
  }

  updateQuotationCategory(categoryId: string, data: any) {
    return axios.put(`quotationproductcategories/${categoryId}`, data).then((res) => res.data)
  }

  updateQuotation(quotationId: string, data: any) {
    return axios.put(`quotations/${quotationId}`, data).then((res) => res.data)
  }

  createQuotationUrl(quotationId: string) {
    return axios.post(`quotationurls?quotationId=${quotationId}`, {}).then((res) => res.data)
  }

  getQuotationUrl(quotationId: string) {
    return axios.get(`quotationurls/${quotationId}`, {}).then((res) => res.data)
  }

  async getQuotationDetails(id: string) {
    const res = await axios.get("quotations/" + id)
    return res.data
  }

  getQuotationDataByUri(uri: string) {
    //unintercepted
    return axios
      .create()
      .get(`quotationurls/uri?uri=${uri}`)
      .then((res) => {
        try {
          res.data.quotation.categories = res.data.quotation.categories.sort((a: any, b: any) =>
            a.priority < b.priority ? -1 : 1
          )
          res.data.quotation.categories.forEach((cat: any) => {
            cat.products = cat.products.sort((a: any, b: any) =>
              a.product.priority < b.product.priority ? -1 : 1
            )
          })
        } catch (err: any) {
          console.log("sorting failed")
        }
        return res.data
      })
  }

  resetViewsOfQuotationUrl(quotationId: string) {
    return axios.get("quotationurls/" + quotationId + "/resetviews").then((res) => res.data)
  }

  //TEMPLATES
  getQuotationTemplates() {
    let url = "quotationtemplates/list"
    return axios.get(url).then((res) => res.data)
  }

  getQuotationTemplateDetails(id: string) {
    let url = "quotationtemplates/" + id
    return axios.get(url).then((res) => res.data)
  }

  addQuotationTemplate(quotationId: string, alias: string, onlyForUSer: boolean) {
    let url =
      "quotationtemplates/new?quotationId=" +
      quotationId +
      "&alias=" +
      alias +
      "&onlyForUser=" +
      onlyForUSer

    return axios.post(url, null).then((res) => res.data)
  }

  addQuotationFromTemplate(quotationTemplateId: string, saleId: string) {
    let url = "quotations/template?quotationTemplateId=" + quotationTemplateId + "&saleId=" + saleId

    return axios.post(url, null).then((res) => res.data)
  }

  updateQuotationTemplate(quotationTemplateId: string, quotationTemplate: any) {
    let url = "quotationtemplates/" + quotationTemplateId

    return axios.put(url, quotationTemplate).then((res) => res.data)
  }

  deleteQuotationTemplate(quotationTemplateId: string) {
    let url = "quotationtemplates/" + quotationTemplateId

    return axios.delete(url).then((res) => res.data)
  }

  // QUOTATION PRODUCT PRICES

  editQuotationProductPrice(
    quotationProductPriceId: string,
    quotationProductPriceObj: ProductPrice
  ) {
    return axios.put("quotationproductprices/" + quotationProductPriceId, quotationProductPriceObj)
  }

  async downloadPdf(id: string, token: string, preview: boolean): Promise<string> {
    let url = `quotations/${id}/pdf?preview=${preview}`
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  }
}
