
import { computed, reactive, ref, defineComponent, watch } from "vue"
import { useRouter, useRoute } from "vue-router"
import InputNumber from "primevue/inputnumber"
import SalesService from "@/services/SalesService"
import FileUpload, { FileUploadUploaderEvent } from "primevue/fileupload"
import SalesCategory from "@/models/SalesCategory"
import TertiaryButton from "../buttons/TertiaryButton.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import { longToShortCurrency } from "@/helpers/CurrencyHelpers"

export default {
  name: "SalesProductComponent",
  components: { InputNumber, FileUpload, TertiaryButton, PrimaryButton },
  props: {
    product: {
      type: Object,
      default: {
        productDetails: {},
      },
    },
    salesState: {
      type: Object,
    },
    language: {
      type: Object,
    },
    showLinkButton: {
      type: Boolean,
      default: false,
    },
    showImageUploadButton: {
      type: Boolean,
      default: false,
    },
    showProductDetails: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: Object,
    },
    region: {
      type: Object,
    },
  },
  emits: ["close-dialog"],
  setup(props: any, { emit }: any) {
    const salesService = new SalesService()
    const deleteProductDialogVisible = ref(false)
    const linkProductToCategoryDialogVisible = ref(false)
    const linkableCategories = reactive([])
    const linkableCategoriesBeforeChange = ref(null)
    const deleteImageDialogVisible = ref(false)
    const imageToBeDeleted = ref(null)
    const price = ref(0)
    const adjustedPrice = ref(0)
    const frequencyOptions = reactive(["SINGLE", "MONTHLY", "ANNUALLY"])
    const tmpPriceFrequency = ref("SINGLE")
    const selectbuttonrefreshkey = ref(0)

    const visibleProductDetails = computed(() => {
      return props.product?.details?.find((detail: any) => {
        return detail.language === props.language
      })
    })

    const visibleProductPrices: any = computed(() => {
      const foundPriceObj = props.product?.prices?.find((_price: any) => {
        return _price.region === props.region?.name && _price.currency === props.currency?.name
      })
      if (foundPriceObj) {
        return foundPriceObj
      } else {
        return {
          price: 0,
          adjustedPrice: 0,
          currency: props.currency?.name,
          frequency: tmpPriceFrequency.value,
          region: props.region?.name,
        }
      }
    })

    function saveProduct() {
      if (props.product?.id) {
        //update product
        salesService
          .updateProduct(props.product.id, {
            alias: props.product.alias,
            priority: props.product.priority,
            sapCode: props.product.sapCode,
          })
          .then((productId) => {
            //updateDetails
            //if exists
            let done = 2

            function checkDone() {
              done--
              if (done === 0) {
                props.salesState.getProductsList()
              }
            }

            if (visibleProductDetails.value.id) {
              salesService
                .updateProductDetails(
                  props.product.id,
                  visibleProductDetails.value.id,
                  visibleProductDetails.value
                )
                .then(() => {
                  checkDone()
                })
            } else {
              //if doesn't exist yet
              salesService
                .createProductDetails(
                  props.product?.id,
                  props.salesState.category.id,
                  visibleProductDetails.value
                )
                .then(() => {
                  checkDone()
                })
            }

            //updatePrice
            //if exists
            if (visibleProductPrices?.value?.id) {
              //do stuff
              salesService
                .updateProductPrice(visibleProductPrices?.value?.id, {
                  price: visibleProductPrices.value.price,
                  adjustedPrice: visibleProductPrices.value.adjustedPrice,
                  purchasePrice: visibleProductPrices.value.purchasePrice,
                  interCoPrice: visibleProductPrices.value.interCoPrice,
                  currency: props.currency?.name,
                  frequency: visibleProductPrices.value.frequency,
                  region: props.region?.name,
                })
                .then(() => {
                  checkDone()
                })
            } else {
              salesService
                .createProductPrice(props.product.id, {
                  price: visibleProductPrices.value.price,
                  adjustedPrice: visibleProductPrices.value.adjustedPrice,
                  purchasePrice: visibleProductPrices.value.purchasePrice,
                  interCoPrice: visibleProductPrices.value.interCoPrice,
                  currency: props.currency?.name,
                  frequency: visibleProductPrices.value.frequency,
                  region: props.region?.name,
                })
                .then(() => {
                  checkDone()
                })
            }
          })
      } else {
        salesService
          .createProduct({
            alias: props.product.alias,
            priority: props.product.priority,
            sapCode: props.product.sapCode,
          })
          .then((productId) => {
            salesService
              .linkProductToCategory(props.salesState.category.id, productId)
              .then((res) => {
                const cachedCategoryId = props.salesState.category.id
                props.salesState.addProductDialogVisible = false
                props.salesState.getCategories(() => {
                  resetCategoryAndProducts(cachedCategoryId)
                })
              })
          })
      }
      emit("close-dialog")
    }

    function deleteProduct() {
      salesService.deleteProduct(props.product.id).then((res) => {
        const cachedCategoryId = props.salesState.category.id
        props.salesState.getCategories(() => {
          resetCategoryAndProducts(cachedCategoryId)
        })
      })
    }

    function resetCategoryAndProducts(categoryId: string) {
      //get categories clear category so we need to set it back with id
      const foundCategory = props.salesState.categories.find((cat: any) => cat.id === categoryId)
      foundCategory.active = true
      props.salesState.category = foundCategory

      //reload products
      props.salesState.getProductsList()
      //emit('reload-parent')-
    }

    function showDeleteProductDialog() {
      deleteProductDialogVisible.value = true
    }

    function confirmDeleteProduct() {
      salesService.deleteProduct(props.product.id).then((res) => {
        const cachedCategoryId = props.salesState.category.id
        props.salesState.getCategories(() => {
          resetCategoryAndProducts(cachedCategoryId)
        })
      })
    }

    function cancelDeleteProduct() {
      deleteProductDialogVisible.value = false
    }

    async function uploadProductImage(event: FileUploadUploaderEvent) {
      const file: File = Array.isArray(event.files) ? event.files[0] : event.files
      const uuid = await salesService.createProductImage(props.product.id, file)
      handleImageUploaded(uuid)
    }

    function showLinkProductToCategoryDialog(productToLink: any) {
      //copy the categories so we work on separate entities
      linkableCategories.length = 0
      props.salesState.categories.forEach((cat: SalesCategory) => {
        const copy = JSON.parse(JSON.stringify(cat))
        if (productToLink.categories?.length) {
          copy.active = productToLink.categories.some(
            (linkedCategory: SalesCategory) => linkedCategory.id === copy.id
          )
        } else {
          if (props.salesState?.category?.id === copy.id) {
            copy.active = true
          }
        }
        linkableCategories.push(copy)
      })
      //cache linkableCategories to link
      linkableCategoriesBeforeChange.value = JSON.parse(JSON.stringify(linkableCategories))
      //show dialog
      linkProductToCategoryDialogVisible.value = true
    }

    function linkProdToCat(category: any) {
      category.active = !category.active
    }

    function saveLinks() {
      const catsThatNeedAction: Array<any> = []
      linkableCategories.forEach((cat: any) => {
        if (
          cat.active &&
          !linkableCategoriesBeforeChange.value?.find((_cat: any) => {
            return cat.id === _cat.id
          })?.active
        ) {
          catsThatNeedAction.push({
            id: cat.id,
            action: "add",
          })
        }
        if (
          !cat.active &&
          linkableCategoriesBeforeChange.value?.find((_cat: any) => {
            return cat.id === _cat.id
          })?.active
        ) {
          catsThatNeedAction.push({
            id: cat.id,
            action: "delete",
          })
        }
      })

      Promise.all(
        catsThatNeedAction.map((cat: any) =>
          cat.action === "add"
            ? salesService.linkProductToCategory(cat.id, props.product.id)
            : salesService.unlinkProductFromCategory(cat.id, props.product.id)
        )
      ).then(() => {
        const cachedCategoryId = props.salesState.category?.id
        linkProductToCategoryDialogVisible.value = false
        if (props.salesState.getCategories) {
          props.salesState.getCategories(() => {
            resetCategoryAndProducts(cachedCategoryId)
          })
        }
      })
    }

    function showDeleteImageDialog(image: any) {
      imageToBeDeleted.value = image
      deleteImageDialogVisible.value = true
    }

    function confirmDeleteImage() {
      salesService.deleteImage(imageToBeDeleted.value.id).then((res: any) => {
        deleteImageDialogVisible.value = false
        //reload products
        //props.salesState.getProductsList()
        handleDeleteImage(imageToBeDeleted.value.id)
      })
    }

    function handleImageUploaded(imageId: any) {
      salesService.getProductImageDetails(imageId).then((imageDetails: any) => {
        salesService.getProductImage(imageId).then((image: any) => {
          props.product.images.push(Object.assign(imageDetails, { src: image }))
        })
      })
    }

    function handleDeleteImage(imageId: any) {
      const spliceIndex = props.product.images.findIndex((img: any) => img.id === imageId)
      props.product.images.splice(spliceIndex, 1)
    }

    function changePriority(event: any) {
      props.product.priority = event.value
    }

    function changePriceFrequency(event: any, prices: any) {
      if (visibleProductPrices?.prices?.frequency) {
        visibleProductPrices.prices.frequency = event.frequency
      } else {
        tmpPriceFrequency.value = event.frequency
      }
      selectbuttonrefreshkey.value++
      selectbuttonrefreshkey.value++
    }

    return {
      saveProduct,
      deleteProduct,
      visibleProductDetails,
      showDeleteProductDialog,
      deleteProductDialogVisible,
      cancelDeleteProduct,
      confirmDeleteProduct,
      uploadProductImage,
      showLinkProductToCategoryDialog,
      linkProductToCategoryDialogVisible,
      linkProdToCat,
      linkableCategories,
      saveLinks,
      deleteImageDialogVisible,
      showDeleteImageDialog,
      confirmDeleteImage,
      changePriority,
      visibleProductPrices,
      price,
      adjustedPrice,
      frequencyOptions,
      changePriceFrequency,
      selectbuttonrefreshkey,
      longToShortCurrency,
    }
  },
}
